
import {ref, watch} from "vue";
import {ContentLoader} from 'vue-content-loader';
import store from "@/store";
import {getView, filtroList} from "@/services/UsuarioService";

export default {
  name: "visualizarServicoModal",

  props: {
    usuario: {
      type: Object,
      required: true,
    }
  },

  components: {
    ContentLoader
  },

  setup(props) {
    let animate = ref(true);
    const filtrosUusario = ref([]);

    store.getters.layoutConfig("distribuidor.name_empresa");

    watch(() => props.usuario, () => {
      ListaView(props.usuario?.codUsuario);
      filtrosUsuario(props.usuario?.codUsuario)
    })

    const usuarioModal: any = ref({});

    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    async function ListaView(codServico) {
      animate.value = true;

      let response = await getView(codServico);

      usuarioModal.value = response;

      animate.value = false;
    }

    function procuraAcesso(usuarioModal){
      if  ((usuarioModal) == -1){
        return "Administrador";
      };
       if ((usuarioModal) == 1){
        return distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda";
      };
      if ((usuarioModal) == 2){
        return "Consultor";
      };
      if ((usuarioModal) == 3){
        return "Orçamentista";
      };
      if ((usuarioModal) == 4){
        return "Controlador de quadro";
      };
      if ((usuarioModal) == 5){
        return "Visualizador de quadro";
      };
      
    };

    async function filtrosUsuario(codUsuario){
        let filter = await filtroList(codUsuario)
        filtrosUusario.value = filter;
    }

    

    return {
      animate,
      ListaView,
      usuarioModal,
      procuraAcesso,
      filtrosUsuario,
      filtrosUusario,
      distribuidor
    }
  }
}
