
import {ref, watch} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useAlert from "@/composables/Alert.ts";
import {hideModal} from '@/core/helpers/dom';
import {deleteUsuario} from "@/services/UsuarioService";
import { useStore } from "vuex";

export default {
  name: "deletarusuarioModal",

  components:{
    deleteUsuario
  },

  props: {
    usuario: {
      type: Object,
      required: true,
    }
  },

  emits: ["atualizar"],

  setup(props, { emit }) {
    const logs: any = ref({});    
    let loading = ref(false);

    const {showTimeAlert} = useAlert();

    const dataFormatada: any = ref("");

    const store = useStore();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    watch(() => props.usuario, () => {
        logs.value = props.usuario     
    });

    function procuraAcesso(codNivel){
      if  ((codNivel) == -1){
        return "Administrador";
      };
       if ((codNivel) == 1){
        return distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda";
      };
      if ((codNivel) == 2){
        return "Consultor";
      };
      if ((codNivel) == 3){
        return "Orçamentista";
      };
      if ((codNivel) == 4){
        return "Controlador de quadro";
      };
      if ((codNivel) == 5){
        return "Visualizador de quadro";
      };
      
    };

    async function deletarUsuario(codUsuario) {
      loading.value = true
      await deleteUsuario(codUsuario).then(() => {
        emit("atualizar");
        showTimeAlert("Usuário excluído com sucesso");
        const elementModal = document.getElementById("kt_modallog_1");
        hideModal(elementModal);
      }).catch(e => {
        showTimeAlert("Não foi possível completar a solicitação");
      });
      loading.value = false
    }

    function deletarServico() {
      if (props.usuario) {
        Swal.fire({
          title: "Deseja excluir esse usuário?",
          text: "Essa alteração não poderá ser desfeita!",
          showCancelButton: true,
          confirmButtonText: "Excluir usuário",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
            cancelButton: "btn btn-light me-3",
          },
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarUsuario(props.usuario?.codUsuario);
          }
        });
      }
    }

    return {
      logs,
      loading,
      deletarServico,
      dataFormatada,
      procuraAcesso
    }
  }
}
